<template>
  <v-container>
    <div v-if="$testMode" class="test-mode">TEST MODE</div>
  </v-container>
</template>

<style scoped>
.test-mode {
  position: fixed;
  bottom: 0;
  right: 0;
  font-weight: bold;
  color: white;
  padding: 0.5em 2em;
  background-color: red;
  z-index: 1000;
}
</style>

<script>
export default {
  name: 'TestModeView'
};
</script>
