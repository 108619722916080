<template>
  <v-container>
    <v-dialog v-model="dialog" max-width="600" width="100%" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>About</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-card-text>
            <div class="pa-2">
              <h2>AIIU SEMS</h2>
              <p>
                <strong>Version 0.0.1</strong>
              </p>
              <p>
                Developed by Enia Group Pty Ltd - <a href="https://enia.com.au" target="_blank">https://enia.com.au</a>
                for Australian Institute of International Understanding Limited -
                <a href="https://aiiu.com.au" target="_blank">https://aiiu.com.au</a>
              </p>
              <p>Copyright 2022 AIIU. All rights reserved.</p>
            </div>
          </v-card-text>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-navigation-drawer v-model="noticedrawer" :width="380" temporary fixed right>
      <v-toolbar flat>
        <v-toolbar-title>Messages</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="noticedrawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list>
        <v-divider></v-divider>
        <template v-for="(item, index) in notifications">
          <v-list-item :key="item.title" :to="item.link">
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index < notifications.length - 1" :key="index"></v-divider>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" :mini-variant.sync="mini" app>
      <v-list dense>
        <template v-for="item in menuItemsListBasedOnPermission">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group v-else-if="item.children" :key="item.text" v-model="item.model" :prepend-icon="item.icon">
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" :to="child.link">
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> {{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" :to="item.link">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <router-link to="/dashboard" class="text-decoration-none white--text hidden-sm-and-down"
          ><span>SEMS</span></router-link
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="noticedrawer = !noticedrawer" icon>
        <v-badge :content="messages" :value="messages" color="red" overlap>
          <v-icon> mdi-bell </v-icon>
        </v-badge>
      </v-btn>
      <v-btn to="/dashboard" icon>
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <v-menu bottom left offset-y :nudge-width="200">
        <template v-slot:activator="{ on }">
          <v-btn dark icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in settingListBasedOnPermission" :key="i" @click="clickSettingsNav(i)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main class="ma-0 pa-0">
      <v-container fluid fill-height fill-width ma-0 pa-0>
        <v-layout child-flex>
          <router-view></router-view>
        </v-layout>
      </v-container>
    </v-main>
    <Spotlight />
    <TestMode />
  </v-container>
</template>

<script>
import Spotlight from './../components/Spotlight.vue';
import TestMode from './../views/TestMode.vue';
import { can } from '@/utils/permissions';

export default {
  name: 'MainMenuView',
  components: {
    Spotlight,
    TestMode
  },
  props: {
    source: String
  },
  methods: {
    can: can,
    clickSettingsNav(item) {
      const setting = this.settings[item];
      if (setting.popup) {
        this.dialog = true;
      } else if (setting.link) {
        if (setting.link.indexOf('http') != -1) {
          window.open(setting.link);
        } else {
          this.$router.push(setting.link);
        }
      } else if (setting.logout) {
        this.$store.dispatch('auth/logout');
      }
    }
  },
  computed: {
    messages: function () {
      return this.notifications.length;
    },
    /**
     * Filter menu items based on permissions
     */
    menuItemsListBasedOnPermission: function () {
      return this.menuItems.filter(menuItem => menuItem.permission == undefined || this.can(menuItem.permission));
    },
    /**
     * Filter settings list based on view permission
     */
    settingListBasedOnPermission: function () {
      return this.settings.filter(setting => setting.permission == undefined || this.can(setting.permission));
    }
  },
  data: () => ({
    dialog: false,
    drawer: null,
    noticedrawer: null,
    mini: false,
    menuItems: [
      { icon: 'mdi-account-group', text: 'Groups', link: '/groups', permission: 'view groups' },
      { icon: 'mdi-domain', text: 'Organisations', link: '/organisations', permission: 'view organisations' },
      { icon: 'mdi-account-multiple', text: 'People', link: '/people', permission: 'view people' },
      {
        icon: 'mdi-account-supervisor',
        text: 'Family Members',
        link: '/family-members',
        permission: 'view organisations'
      },
      { icon: 'mdi-account', text: 'Staff Members', link: '/staff-members', permission: 'view people' },
      { icon: 'mdi-finance', text: 'Reports', link: '/reports', permission: 'view reports' }
    ],
    notifications: [
      // {
      //   id: 1,
      //   title: 'New Organisation',
      //   description: 'A new organisation `ABC dentist` was added!',
      //   link: '/organisations/new'
      // },
      // { id: 2, title: 'Paperwork due', description: 'There is a paperwork due today!', link: '/groups/new' }
    ],
    settings: [
      { title: 'About', popup: { title: 'About AIIU SEMS', message: 'AIIU application version 0.0.1' } },
      { title: 'Settings', link: '/settings', permission: 'view settings' },
      { title: 'Users', link: '/users', permission: 'view users' },
      { title: 'User Groups', link: '/usergroups', permission: 'view user groups' },
      { title: 'Change Password', link: '/change-password' },
      { title: 'Help', link: 'https://docs.aiiusems.com.au/' },
      { title: 'Logout', logout: true }
    ]
  })
};
</script>
