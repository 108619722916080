<template>
  <v-overlay :value="spotlightOpen" @keydown.esc="closeSpotlightOverlay()">
    <v-autocomplete
      v-model="spotlight"
      :loading="loading"
      :items="items"
      :search-input.sync="search"
      @change="spotlightActionUpdate()"
      ref="spotlightField"
      item-text="title"
      item-value="id"
      clearable
      cache-items
      class="mx-4"
      flat
      hide-no-data
      hide-details
      filled
      rounded
      solo-inverted
      style="max-width: 960px; width: 960px"
    ></v-autocomplete>
  </v-overlay>
</template>

<script>
import store from '@/store/index';
import { mapGetters } from 'vuex';

export default {
  name: 'Spotlight',
  data() {
    return {
      spotlight: null,
      loading: false,
      items: [],
      options: [
        {
          id: 1,
          title: 'create organisation',
          action: 'create'
        },
        {
          id: 2,
          title: 'new organisation',
          action: 'create'
        },
        {
          id: 3,
          title: 'search organisation',
          action: 'search'
        },
        {
          id: 4,
          title: 'edit organisation',
          action: 'search'
        }
      ],
      search: null
    };
  },
  computed: {
    ...mapGetters('ui', ['spotlightOpen'])
  },
  watch: {
    search(value) {
      value && value !== this.select && this.querySelections(value);
    },
    spotlightOpen: function () {
      if (!this.spotlightOpen) return;
      requestAnimationFrame(() => {
        this.$refs.spotlightField.$refs.input.focus();
      });
    }
  },
  methods: {
    spotlightActionUpdate() {
      if (!this.spotlight) return false;
      // const whatToDo = this.options[this.spotlight];
      this.closeSpotlightOverlay();
    },
    closeSpotlightOverlay() {
      store.dispatch('ui/toggleSpotlight', false);
    },
    querySelections(value) {
      this.loading = true;
      this.items = this.options.filter(e => {
        return (e.title || '').toLowerCase().indexOf((value || '').toLowerCase()) > -1;
      });
      this.loading = false;
    }
  }
};
</script>
